.dataStatusPage {
  .omics-selection .btn {
    min-width: 100px;
  }

  label {
    font-size: inherit;
    line-height: 1;
    margin-right: 0.5rem;
    margin-bottom: 0;
    width: auto;
  }

  .form-control {
    width: 240px;
  }

  .pagination-page-size {
    margin-bottom: 1rem;
    
    select {
      margin-right: 0.65rem;
      max-width: 75px;
    }
  }

  .pagination-footer {
    margin-bottom: 1.0rem;

    .btn {
      min-width: 60px;
      max-width: 80px;

      &.disabled-btn {
        pointer-events: none;
      }
    }
  }

  .page-title {
    .data-transfer-guidelines-link {
      span {
        font-size: 1.85rem;
      }
    }
  }
}

.data-qc-status-panel {
  .card-body {
    padding: 0;
  }

  .help-page-link {
    .material-icons {
      color: #007bff;
    }

    .btn-link {
      color: #007bff;
      margin: 0;
      padding: 0;

      &:hover {
        color: #0056b3;
        text-decoration: underline;
      }
    }
  }
}

.dataStatusTable {
  border-collapse: collapse;
  font-size: 0.85rem;
  margin-bottom: 0;
  text-align: left;

  th, td {
    border: 1px solid #dee2e6;
    line-height: 1;
    padding: 0.5rem;
    vertical-align: middle;
  }

  tr:first-child th, tr:first-child td {
    border-top: 0;
  }

  tr th:first-child, tr td:first-child {
    border-left: 0;
  }

  tr:last-child td {
    border-bottom: 0;
  }

  tr th:last-child, tr td:last-child {
    border-right: 0;
  }

  thead th {
    background-color: #dee2e6;
    vertical-align: middle;

    .material-icons {
      color: #888;
    }
  }

  tbody {
    .phase {
      background-color: lighten($accent-green-shade-light, 22%);
    }

    .PASS1A-06 {
      background-color: lighten($primary-blue-shade-light, 22%);
    }

    .PASS1B-06 {
      background-color: lighten($accent-yellow-shade-light, 22%);
    }

    .PASS1C-06 {
      background-color: lighten($accent-violet-shade-light, 45%);
    }

    .HUMAN {
      background-color: lighten($accent-red-shade-light, 25%);
    }

    .PASS, .FAIL, .WARN, .NOT.AVAILABLE {
      text-align: center;

      span {
        color: #fff;
        display: inline-block;
        padding: .4em .75em;
        font-size: 80%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      }
    }

    .PASS {
      span {
        background-color: #28a745;
      }
    }

    .FAIL {
      span {
        background-color: #dc3545;
      }
    }

    .WARN {
      span {
        background-color: #ea580c;
      }
    }

    .NOT.AVAILABLE {
      span {
        background-color: $gray-semi-transparent;
      }
    }

    .issues-1, .raw_manifest-1, .checksum_mismatch-1 {
      background-color: lighten($accent-red-shade-light, 25%);
    }

    .issues-2, .raw_manifest-2, .checksum_mismatch-2 {
      background-color: lighten($accent-red-shade-light, 20%);
    }

    .issues-3, .raw_manifest-3, .checksum_mismatch-3 {
      background-color: lighten($accent-red-shade-light, 15%);
    }

    .issues-4, .raw_manifest-4, .checksum_mismatch-4 {
      background-color: lighten($accent-red-shade-light, 10%);
    }

    .issues-5, .raw_manifest-5, .checksum_mismatch-5 {
      background-color: lighten($accent-red-shade-light, 5%);
    }

    .issues-6, .raw_manifest-6, .raw_manifest-7, .checksum_mismatch-6 {
      background-color: lighten($accent-red-shade-light, 0%);
    }

    .btn-view-qc-report {
      font-size: 0.8rem;
      font-weight: normal;
      color: #007bff;
      margin: 0 auto;
      padding: 0;

      &:hover {
        text-decoration: none;
      }

      &:focus, &:active {
        box-shadow: none;
        outline: none;
        text-decoration: none;
      }

      .material-icons {
        font-size: 1.0rem;
        margin-left: 0.2rem;
      }
    }
  }
}

.QcReportHelp {
  h6 {
    font-style: italic;
  }

  .terms-definitions {
    .term {
      font-weight: bold;
    }

    .untargeted {
      background-color: #eafea2;
    }

    .targeted {
      background-color: #feeca2;
    }

    .prot-pr {
      background-color: #eafea2;
    }

    .prot-ph {
      background-color: #b3ecff;
    }

    .prot-ac {
      background-color: #ffa500;
    }

    .prot-ub {
      background-color: #ff66ff;
    }
  }
}

.nav-tabs {
  .nav-item {
    .nav-link.active {
      font-weight: 600;
    }
  }
}

/* data table */
.QCReportByPhaseTabPane {
  table {
    tfoot th {
      border: none;
      color: $gray;
      font-size: 0.8rem;
      height: 150px;
      text-align: left;
      white-space: nowrap;
      vertical-align: top;

      &:last-child {
        color: transparent;
        padding-left: 8px;
      }

      &:not(:first-child) {
        padding-bottom: 0;
      }

      .footer-content-container {
        width: 40px;

        /* anlge positioning for table header items */
        .footer-label {
          padding: 2px;
          transform-origin: top left;
          transform: translateX(35px) rotate(45deg);
          float: left;
        }
      }
    }

    tbody td {
      font-size: 0.85rem;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;

      &:last-child {
        border-top: none;
        font-weight: 600;
        padding-left: 8px;
        text-align: left;
      }

      .no-sample-submission {
        background-color: $stanford-cool-grey;

        span {
          visibility: hidden;
        }
      }

      .sample-submission-count {
        background-color: $primary-blue-shade-medium;

        span {
          visibility: hidden;
        }
      }
    }
  }

  table.HUMAN {
    tfoot th {
      .footer-content-container {
        width: 80px;

        /* anlge positioning for table header items */
        .footer-label {
          transform: translateX(100px) rotate(45deg);
        }
      }
    }
  }

  .legend-item {
    font-size: 0.95rem;

    .legend-item-color.submitted {
      background-color: $primary-blue-shade-medium;
      padding: 0.5rem;
    }

    .legend-item-color.not-submitted {
      background-color: $stanford-cool-grey;
      padding: 0.5rem;
    }
  }

  .PASS1C-table-wrapper {
    margin-top: 2.5em;
  }
}
