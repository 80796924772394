.technicalGuidesPage {
  &.phenotype {
    .technical-guide-content-container {
      h3 {
        background: -webkit-linear-gradient(45deg, #a64d79, #1c4587);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
  
        .study-title-species-icon {
          font-size: 1.85rem;
        }
      }

      table {
        td:first-child {
          width: 35%;
        }
      }
    }
  }
}