.dataReleasePage {
  .warning-note {
    display: none !important;
    margin-bottom: 0;
  }

  .page-title {
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 80px;

    h1 {
      margin-bottom: 0;
    }
  }

  .page-header {
    .btn-toolbar {
      .btn {
        padding: 0.2em 0.5em;

        .material-icons {
          margin-right: 0.1em;
        }
      }
    }
  }

  .release-timeline {
    ul {
      padding-left: 0;
    }

    li {
      color: $stanford-cardinal-red;

      .material-icons {
        font-size: 1.1rem;
        margin-right: 0.1em;
      }
    }
  }

  .release-main-section {
    border-left: 2px solid #e1e4e8;
    padding-right: 0;

    .release-header {
      color: $stanford-cardinal-red;
      font-weight: 400;
    }

    i.material-icons.download-checkbox {
      color: $gray;
      cursor: pointer;
      font-size: 1.15rem;
    }

    .release-content {
      .card {
        border-color: $gray-semi-transparent;

        table.additional-download-table {
          border-collapse: collapse;
          font-size: 0.85rem;
          margin-bottom: 0;

          td {
            border: 1px solid #dee2e6;
            padding: 0.55rem 0.75rem;
            vertical-align: middle;
          }
        }

        .release-data-links-table-container,
        .additional-download-table-container {
          h6 {
            color: #343a40;
            font-weight: 600;
            margin-top: 1.25rem;
          }
        }

        table.release-data-links-table {
          border-collapse: collapse;
          margin-bottom: 0;
          text-align: center;

          th {
            border: 1px solid #343a40;
            padding: 0.55rem 0.75rem;
            vertical-align: middle;

            &.col-data-type {
              width: 50%,
            }

            &.col-command-line-download,
            &.col-web-download {
              width: 25%,
            }
          }

          td {
            border: 1px solid #dee2e6;
            padding: 0.55rem 0.75rem;
            vertical-align: middle;

            img {
              margin-right: 0.35rem;
              width: auto;
              height: 1.0rem;
              opacity: 0.75;
            }

            &.release-data-download-link {
              line-height: 0;

              .copy-to-clipboard-wrapper {
                margin: 0 auto;
                position: relative;
                width: 30px;

                .copy-to-clipboard-button {
                  color: $primary-blue;
                  cursor: pointer;
                }

                .tooltip-on-top {
                  background-color: #dadfcf;
                  color: #000;
                  display: none;
                  font-size: 0.75rem;
                  line-height: 100%;
                  padding: 0.65rem;

                  code {
                    display: block;
                    font-size: 0.8rem;
                    margin-bottom: 0.5rem;
                  }

                  i::after {
                    background-color: #dadfcf;
                  }
                }

                &:hover .tooltip-on-top {
                  display: block;
                  visibility: visible;
                  opacity: 1;
                }
              }

              .btn-data-download {
                background-color: #fff;
                border: none;
                color: $primary-blue;
                font-size: 1.0rem;
                margin: 0 0.2rem 0 0;
                padding: 0;
                vertical-align: middle;

                &:hover, &:focus, &:active, &.active {
                  background-color: #fff;
                  color: $primary-blue;
                }

                &:focus {
                  outline: none;
                }

                &.disabled {
                  color: $gray;
                  cursor: not-allowed;
                }
              }

              .file-size {
                position: relative;
                top: 2px;
              }
            }
          }

          th:first-child, td:first-child {
            text-align: left;
          }

          tbody tr:first-child td {
            border-top: 0;
          }
        }
      }
    }

    .release-links {
      a {
        font-size: 0.85rem;
        margin: 0.75rem 0;
      }
    }

    .additional-release-download-header {
      color: $gray;
      border-bottom: solid 1px #e1e4e8;
      margin: 2.0rem 0 0.75rem 0;
      padding-bottom: 0.25rem;
    }

    .raw-files-download-section,
    .intermediate-files-download-section {
      code {
        margin-left: 0.5rem;
      }
    }

    .release-documentation-section {
      p {
        margin-bottom: 0.5rem;
      }
    }
  }

  .modal {
    .modal-title {
      color: $stanford-cardinal-red;
    }

    .loading-spinner {
      text-align: center;
    }
  }

  .view-more-button-container {
    .view-more-button-wrapper {
      border-left: 2px solid #e1e4e8;
      padding-right: 0;
    }
  }

  .release-data-status-info-container {
    .release-data-status-info {
      border-left: 2px solid #e1e4e8;
      padding-right: 0;
    }
  }
}

/* specificity for external release view */
.release-entry-container {
  &.external {
    .release-timeline {
      li {
        color: $stanford-cool-grey;
      }
    }
  }
}

/* responsive styling */

/* Large devices (desktops, less than 1200px) */
@include media-breakpoint-down(lg) {
  .dataReleasePage {
    .release-entry-item {
      display: flex;
      flex-direction: column;

      .release-timeline {
        max-width: 100%;
        padding-bottom: 0 !important;
      }

      .release-main-section {
        border-left: none;
        max-width: 100%;
        padding-left: 0 !important;
        padding-top: 0 !important;
      }
    }

    .view-more-button-container {
      .d-none {
        display: none !important;
      }

      .view-more-button-wrapper {
        border-left: none;
        padding-left: 0 !important;
      }
    }
  }
}

@media (max-width: 834px) {
  .dataReleasePage {
    flex: 0 0 80% !important;
    max-width: 80% !important;

    &>div {
      flex-direction: column;
      
      .btn-toolbar {
        margin-top: 15px;
      }
    }

    .release-data-links-table {
      .release-data-download-link {
        font-size: 0.8rem;
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;

        .btn-data-download {
          margin-right: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .dataReleasePage {
    flex: 0 0 100% !important;
    max-width: 100% !important;

    &>div {
      .btn-toolbar {
        .btn-group:first-child {
          margin-right: 0 !important;
        }

        .btn-group:last-child {
          display: none;
        }
      }
    }

    .warning-note {
      display: flex !important;
      font-size: 80%;
      justify-content: flex-start;
    }

    .release-entry-item {
      .release-timeline {
        display: block !important;
        float: right !important;

        &>div {
          text-align: right;
        }

        ul {
          display: block !important;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .dataReleasePage {
    .release-data-links-table-container,
    .additional-download-table-container {
      border: 0;
      box-shadow: inset 0 0 0 1px #dee2e6;
      box-sizing: border-box;
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

      .tooltip-on-top {
        width: 220px;

        code {
          white-space: pre-wrap;
          font-size: 0.8rem;
          line-height: 120%;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

@media (max-width: 398.98px) {
  .btn-toolbar {
    .btn {
      font-size: 85%;

      .material-icons {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 320px) {
  .btn-toolbar {
    .btn {
      font-size: 70%;

      .material-icons {
        font-size: 16px;
      }
    }
  }
}

