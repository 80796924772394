.methodsPage {
  margin-bottom: 1.25rem;

  .page-title {
    h3 {
      margin-bottom: 0;
    }
  }

  .methods-summary-container {
    .material-icons {
      line-height: 1.65;
    }
  }
}
