
.oi-external-link {
  font-size: 90%;
}

.linkoutPage {
  .page-title {
    margin-bottom: 1.5rem;

    h3 {
      margin-bottom: 0;
    }
  }

  .motrLink {
    padding-bottom: 1.25rem;

    .h5 {
      a {
        display: block;
        margin-bottom: 0.5rem;
      }

      p {
        line-height: 1.5;
      }
    }

    img {
      padding-right: 0;
      max-height: 20vh;
    }
  }

  a {
    overflow-wrap: break-word;
  }

  .externalLinks {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;

    h4 {
      margin-bottom: 0.65rem;
    }

    .card-img-top {
      height: 20vh;
      width: 95%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border-radius: 10px;
      margin: 0 auto;
    }

    .card-body {
      border-top: 1px solid $gray-200;

      .card-title {
        .inline-link-with-icon {
          text-decoration: none;

          .material-icons {
            font-size: 1.25rem;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .featured-link {
    .card {
      border: none;

      .card-body {
        border-top: none;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

@media (min-width: 948px) and (max-width: 1199.98px) {
  .linkoutPage {
    .externalLinks {
      .card-deck .card {
        min-width: 300px;
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 947.98px) {
  .linkoutPage {
    .externalLinks {
      .card-deck .card {
        min-width: 200px;
      }
    }
  }
}
