.browseDataPage {
  .browse-data-summary-container {
    .browse-data-summary-content {
      .show-collapse-summary-link {
        font-size: inherit;
        font-weight: normal;
        color: #007bff;
        padding: 0;
  
        &:hover {
          text-decoration: none;
        }
  
        &:focus, &:active {
          box-shadow: none;
          outline: none;
          text-decoration: none;
        }
      }

      .bd-callout-info {
        ul {
          padding-left: 1.0rem;
        
        }
      }
    }

    .inline-link-with-icon .material-icons {
      font-size: inherit;
      line-height: 1.5;
    }

    .motrpac-collab-linkout {
      .material-icons {
        color: #ff9900;
        font-size: 5.0rem;
      }
    }

    .bundle-datasets-item {
      .card {
        .bundle-dataset-tags {
          .badge.badge-pill.badge-purple {
            background-color: #6f42c1;
            color: #fff;
          }
        }

        .card-footer {
          background-color: #fff;
          border-top: none;

          .ready-to-download-link {
            background-color: #343a40;
            border-color: #343a40;
            border-radius: 0.25rem;
            color: #fff;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.5;
            padding: 0.465rem 0.75rem;
            text-align: center;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      
            &:hover, &:focus, &:active {
              background-color: #23272b;
              border-color: #23272b;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .selective-data-downloads-card {
    .card-header {
      .material-icons {
        font-size: 1.85rem;
      }
    }

    &.external-access {
      max-width: 22rem;
    }
  }

  .data-download-selective-files {
    /* tab UI temporary removed
    .nav.nav-tabs {
      margin-left: 15px;
      margin-right: 15px;
    }
    */
    .link-back {
      a {
        text-decoration: none;
        width: fit-content;
      }
    }
  }

  label {
    margin-right: 0.5rem;
    margin-bottom: 0;
    width: auto;
  }

  .form-control {
    width: 240px;
  }

  .pagination-page-size,
  .file-download-button {
    margin-bottom: 0.85rem;

    button {
      padding-left: 0.5rem;
    }
    
    select {
      margin-right: 0.65rem;
      max-width: 65px;
    }
  }

  .file-download-button {
    .btn {
      &:disabled {
        cursor: not-allowed;

        &:hover {
          background-color: #007bff;
        }
      }
    }
  }

  .data-download-modal {
    .loading-spinner {
      text-align: center;
    }

    .modal-body {
      padding-top: 0;
      padding-bottom: 0;
    }

    .file-download-list {
      margin-bottom: 0;
    }

    .file-download-list-item {
      td {
        vertical-align: middle;

        &.file-download-link-item {
          position: relative;
          text-align: center;
    
          a span {
            position: relative;
            top: 4px;
          }
        }
      }

      &:first-child {
        td {
          border-top: none;
        }
      }
    }
  }

  .pagination-footer {
    margin-bottom: 1.0rem;

    .btn {
      min-width: 60px;
      max-width: 80px;

      &.disabled-btn {
        pointer-events: none;
      }
    }
  }
}

.browse-data-container {
  .card-body {
    padding: 0;
  }

  .browse-data-filter-group {
    .filter-module {
      .card-header {
        padding: 0.4rem 0.85rem;

        .data-filter-info-icon {
          color: $gray;
          font-size: 1.1rem;
          cursor: pointer;
        }
      }

      .card-body {
        padding: 0.3rem;
      }

      .filterBtn {
        background-color: #fff;
        border: 1px solid $gray-semi-transparent;
        color: $stanford-cool-grey;
        font-size: .9rem;
        font-weight: 500;
        margin: 0.3rem;
        padding: .2rem .4rem;
        white-space: normal;

        &:disabled {
          cursor: not-allowed;
        }
      }

      .activeFilter {
        background-color: $stanford-cool-grey;
        color: #fff;
      }
    }
  }
}

.browseDataTable {
  border-collapse: collapse;
  font-size: .9rem;
  margin-bottom: 0;
  text-align: left;

  th, td {
    border: 1px solid #dee2e6;
    line-height: 1;
    padding: 0.65rem 0.5rem;
  }

  tr:first-child th, tr:first-child td {
    border-top: 0;
  }

  tr th:first-child, tr td:first-child {
    border-left: 0;
  }

  tr:last-child td {
    border-bottom: 0;
  }

  tr th:last-child, tr td:last-child {
    border-right: 0;
  }

  thead th {
    background-color: #dee2e6;
    padding: 0.5rem;

    .material-icons {
      color: #888;
    }
  }

  tbody {
    td {
      padding: 0.65rem 0.5rem;
      vertical-align: middle;

      &.tissue_name.not-available {
        background-color: #f3f3f3;
      }

      &.assay.not-available {
        background-color: #f3f3f3;
      }

      &.omics.not-available {
        background-color: #f3f3f3;
      }

      &.category.Phenotype {
        background-color: #f3f3f3;

        span {
          display: none;
        }
      }
    }
  }
}

/**
  * Category filter group panel header
  * info icon to show/hide tooltip
  */
.data-filter-info-icon-wrapper {
  position: relative;
}

.data-filter-info-icon-wrapper:hover {
  .tooltip-on-right {
    visibility: visible;
    opacity: 1;
  }
}

/**
 * Open access bundle data download link
 */
.bundle-data-callout-title-container {
    button {
      color: #007bff;
      font-size: 1rem;
      padding: 0;

      &:focus, &:active {
        outline: none;
      }
    }
}
.open-access-bundle-data-title-container {
  .open-access-bundle-data-size {
    color: #aaa;
    font-size: 0.9rem;
    font-style: italic;
  }
}
.open-access-bundle-data-download-container {
  .btn-bundle-data-download {
    padding-left: 0.5rem;

    &:focus, &:active {
      outline: none;
    }
  }
}

#openAccessBundleDataDownloadModal {
  #openAccessBundleDataDownloadModalLabel {
    color: $stanford-black;
    margin-bottom: 0;
  }

  .modal-close-button {
    padding-left: 0.5rem;

    .button-text {
      font-size: larger;
    }
  }
}

.open-access-bundle-data-sets {
  .card {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);

    .ready-to-download-link {
      background-color: #2094e8;
      border-color: #2094e8;
      border-radius: 0.25rem;
      color: #fff;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 0.375rem 0.75rem;
      text-align: center;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:hover, &:focus, &:active {
        background-color: #1478c1;
        border-color: #1478c1;
        text-decoration: none;
      }
    }
  }
}
