.authLoading {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color: $blue-transparent;
  text-align:center;
  padding-top:40vh;
  span {
    font-size:400%;
  }
  span, h3{
    animation: loadingAnimation 3s infinite;
    color: $primary-blue;
  }
  a :hover{
    text-decoration: none;
  }
}

@keyframes loadingAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
