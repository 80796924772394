/**
 * Tooltip Styles
 */
.tooltip-on-top {
  top: -15px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 0.35rem 0.5rem;
  color :#fff;
  background-color: #000;
  font-weight: normal;
  font-size: 0.65rem;
  border-radius: 4px;
  position: absolute;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  white-space: nowrap;
}

.tooltip-on-top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip-on-top i::after {
  content:'';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%,-50%) rotate(45deg);
  background-color: #000;
}

/**
 * Tooltip-on-right Styles
 */
.tooltip-on-right {
  top: 50%;
  left: 100%;
  margin-left: 12px;
  transform: translateY(-50%);
  padding: 0.35rem 0.5rem;
  color :#fff;
  background-color: #000;
  font-weight: normal;
  font-size: 0.75rem;
  border-radius: 4px;
  position: absolute;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  white-space: nowrap;
}

.tooltip-on-right i {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}

.tooltip-on-right i::after {
  content:'';
  position: absolute;
  width: 12px;
  height: 12px;
  top: 50%;
  transform: translate(50%, -50%) rotate(45deg);
  background-color: #000;
}

.sidebar.expanded #sidebar-toggle.tooltip-on-right {
  left: 3.65rem;
}

/**
 * Tooltip Styles
 */
.tooltip-on-bottom {
  top: 50px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 0.35rem 0.5rem;
  color :#fff;
  background-color: #000;
  font-weight: normal;
  font-size: 0.65rem;
  border-radius: 4px;
  position: absolute;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  white-space: nowrap;
  display: none;
}

.tooltip-on-bottom i {
  position: absolute;
  top: -50%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip-on-bottom i::after {
  content:'';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%,50%) rotate(45deg);
  background-color: #000;
}
