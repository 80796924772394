.sidebar {
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  top: 0;
  bottom: 0;
  left: 0;
  padding: 4.25em 0 0;
  z-index: 100;

  .sidebar-sticky {
    position: sticky;
  }

  .sidebar-btn-toggle {
    background-color: transparent;
    background-image: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    margin: 0 0 0.25rem 1.0rem;
    padding: 0;
    outline: 0;

    &:hover, &:focus, &:active, &.active {
      outline: 0;
      box-shadow: none;
    }

    .nav-sidebar-btn-icon {
      color: $gray-semi-transparent;
      font-size: 1.75em;
      line-height: 1;
    }
  }

  .sidebar-heading {
    font-weight: $bold-weight;
  }

  .nav-link {
    font-size: 0.9rem;
    padding: 0.65rem 1.0rem;
    white-space: nowrap;

    &:hover {
      background-color: $blue-transparent;
      color: $primary-blue;
    }

    &.active {
      background-color: $primary-blue;
      color: #fff;
    }

    .icon-Animal {
      margin-left: 0.25rem;
      margin-right: 0.9rem;
    }

    &.disabled-link {
      color: $gray-semi-transparent;
      pointer-events: none;
    }

    .nav-link-icon {
      font-size: 1.75rem;
      margin-right: 0.65rem;
    }
  }
  
  &.expanded {
    width: 15.0rem;
  }

  &.collapsed {
    width: 3.75rem;

    .sidebar-heading {
      display: none;
    }

    .nav-link {
      .nav-link-icon {
        margin-right: 0;
      }

      .nav-link-label {
        display: none;
      }
    }
  }
}

/**
 * Progress Bar Styles
 */
.sidebar-nav-link-wrapper,
.sidebar-toggle-btn-wrapper {
  position: relative;
}

.sidebar-nav-link-wrapper:hover,
.sidebar-toggle-btn-wrapper:hover {
  .tooltip-on-right {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 800px) {
  nav.sidebar {
    max-width: 10.0rem !important;
  }
}
