.user-survey-modal {
  .modal-dialog.modal-dialog-centered {
    max-width: 580px !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .modal-content {
      background-color: #003962;
      border: 1px solid #003256;
      border-radius: 0.75rem;
      box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.35);
      padding: 0.85rem;

      .modal-header {
        border-bottom: none;
        padding-bottom: 0;

        h4 {
          color: #fff;
          font-weight: 700;
        }
      }

      .modal-body {
        color: #fff;
        font-size: 1.15rem;
        font-weight: 500;
        text-align: center;
        transition: height 2s ease-in-out;

        .user-survey-response .custom-radios {
          .custom-control.custom-radio {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 60px;
            padding-left: 0;
            text-align: center;
          }

          .form-check-label {
            text-align: center;
          }

          input[type="radio"] {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
          }

          input[type="radio"] + span {
            color: $accent-yellow;
            cursor: pointer;
            font-size: 52px;
            transition: font-size 0.25s;
          }

          input[type="radio"]:hover + span {
            cursor: pointer;
            font-size: 60px;
          }

          input[type="radio"]:checked + span {
            cursor: pointer;
            font-size: 60px;
          }
        }

        .user-survey-response .user-comment {
          border: 1px solid #003256;
          border-radius: 0.5rem;
          box-shadow: inset 0 0 0.5rem 0 rgba(0, 0, 0, 0.25);
          font-size: 1.1rem;

          &::placeholder {
            color: #888;
          }
        }

        .user-survey-response .detailed-user-survey-link {
          font-size: 0.95rem;

          a {
            text-decoration: underline;
          }
        }
      }

      .modal-footer {
        border-top: none;
        padding-top: 0;
      }
    }
  }
}