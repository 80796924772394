.analysisPage {
  .page-title {
    h3 {
      margin-bottom: 0;
    }
  }

  .backButton {
    background-color: #fff;
    border: none;
    color: $primary-blue;
    font-size: 1.0rem;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0.75rem 0 0;
    vertical-align: middle;

    &:hover, &:focus, &:active, &.active {
      background-color: #fff;
      color: $primary-blue;
    }

    &:focus {
      outline: none;
    }
  }

  .analysisType, .subAnalysis { 
    p {
      font-size: 1.0rem;
    }

    img {
      max-width: 100%;
    }

    &.inactiveAnalysis {
      img {
        opacity: .6;
      }
    }

    &.activeAnalysis {
      &:hover {
        border: 1px solid $primary-blue;
        background-color: lighten($blue-transparent, 15%);
        cursor: pointer;
        transition: .2s;
        transform: translateY(-1px);
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25) !important;
      }
    }
  }
}

.geneCentricViewPage {
  .gene-centric-view-summary-container {
    .summary-tooltip-anchor {
      color: $primary-blue;
      cursor: default;
    }
  }

  label {
    font-size: inherit;
    font-weight: 600;
    line-height: 1;
    margin-right: 0.5rem;
    margin-bottom: 0;
    width: auto;

    &.form-check-label {
      font-weight: normal;
    }
  }

  .search-box-input-group {
    .input-group-prepend {
      .material-icons {
        color: $stanford-black;
        font-size: 1.75rem;
        line-height: 0;
      }
    }
    .rbt {
      flex-grow: 3;
    }
  }

  .pagination-page-size,
  .search-results-usage-instructions,
  .view-plots-button {
    margin-bottom: 0.85rem;
    
    select {
      margin-right: 0.65rem;
      max-width: 85px;
    }
  }

  .pagination-footer {
    margin-bottom: 1.0rem;

    .btn {
      min-width: 60px;
      max-width: 80px;

      &.disabled-btn {
        pointer-events: none;
      }
    }
  }

  .card-body.table-ui-wrapper {
    padding: 0;
  }

  .deaResultsTable {
    font-size: 0.9rem;

    thead {
      th:first-child {
        vertical-align: middle;

        input, span {
          display: none;
        }
      }
    }

    tbody {
      td:first-child {
        text-align: center;
      }
    }
  }

  .gene-centric-time-series-plots-container {
    .plot-lengend {
      .legend-icon {
        &.female {
          color: #ff6eff;
        }

        &.male {
          color: #5555ff;
        }
      }
    }
  }
}

.graphicalClusteringPage {
  .graphical-clustering-summary-container {
    .inline-link-with-icon .material-icons {
      font-size: inherit;
      line-height: 1.5;
    }

    #introduction {
      margin-left: 15px;
      margin-right: 15px;

      &.section {
        margin-top: 1.5em;
      }
    }
  }

  .controlPanelContainer-fix-top {
    position: fixed;
    top: 74px;
    z-index: 1001;
    max-width: 258px;
  }

  .tocify {
    background-color: #fff;
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 0.5rem;
    overflow-x: auto;
    transition: top 0.5s ease;
    max-height: calc(100vh - 250px);

    &.toc-fix-top {
      position: fixed;
      top: 144px;
      z-index: 1000;
      max-width: 258px;
    }

    ol {
      list-style: none;
      margin: 0;
      padding: 0;
      
      li {
        font-size: 0.9rem;
        margin: 0;
        padding-top: 0.15rem;
        padding-bottom: 0.15rem;

        a {
          color: #000;
          text-decoration: none;

          &.is-active-link {
            font-weight: bold;
          }
        }
      }
    }

    ol>li>ol {
      padding-left: 1.0rem;
    }

    ol>li>ol>li>ol {
      padding-left: 1.0rem;
    }
  }

  .graphical-clustering-content-container {
    h3 {
      font-size: 1.65rem !important;
    }

    h4 {
      font-size: 1.25rem !important;
    }
  }
}

@media (max-width: 1480px) {
  .analysisPage {
    .animal-phenotype-data {
      .card-body {
        overflow-x: auto;
      }
    }
  }
}
