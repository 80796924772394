.dataAccessPage {
  margin-bottom: 1.25rem;

  .alert-consortia-members-access {
    margin-bottom: 1.75rem;
  }

  p {
    line-height: 1.5;

    .subhead {
      font-weight: $bold-weight;
      display: block;
    }
  }

  .alert-missing-checkbox {
    font-size: 0.85rem;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }

    .material-icons {
      font-size: 1.15rem;
      line-height: 0;
      margin-right: 0.15rem;
    }
  }

  .card {
    margin-top: 2.25rem;

    .card-title {
      color: $stanford-cardinal-red;
    }

    &.e-signature {
      margin-top: 0.35rem;

      .card-body {
        padding-top: 0.45rem;

        .e-signature-label {
          font-weight: $semibold-weight;
        }

        .e-signature-input {
          border: none;
          border-bottom: 1px solid $gray-semi-transparent;
          border-radius: 0;
          font-size: 0.85rem;
          margin-top: 0.5rem;

          &::placeholder {
            color: $gray-semi-transparent;
          }
        }
      }
    }
  }

  .data-use-agreement-container {
    label {
      color: #2e2d29;
      font-size: 0.85rem;
    }

    ul, ol {
      margin-top: 0.45rem;
      margin-bottom: 0;
    }
  }

  .registration-form-container {
    h5 {
      margin-bottom: 1.25rem;
    }

    .personal-info-content,
    .affiliation-info-content {
      label {
        color: #2e2d29;
        font-size: 0.85rem;

        &.required-field {
          &:after {
            content: ' *';
            color: $stanford-bright-red;
          }
        }
      }

      .required-flag {
        font-size: 0.85rem;
        margin-left: 0.5rem;

        .required-flag-text {
          color: $stanford-bright-red;
          margin: 0 0.15rem;
        }
      }

      input[type=text],
      input[type=email],
      textarea {
        font-size: 0.85rem;
      }
    }
  }

  button.registration-submit {
    &:disabled {
      cursor: not-allowed;
    }

    img.in-progress-spinner {
      animation-name: spinner;
      animation-duration: 1.75s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      -webkit-filter: invert(100%) brightness(100%) contrast(100%);
      filter: invert(100%) brightness(100%) contrast(100%);
      height: 30%;
      width: 30%;
    }

    @keyframes spinner {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
    }
  }
}

/* Medium devices (tablets, less than 992px) */
@include media-breakpoint-down(md) {
  .dataAccessPage {
    .form-footer {
      align-items: start;
      flex-direction: column;
    }

    .registration-button-group {
      margin-top: 1.25rem;
    }
  }
}

@media (max-width: 600px) {
  .dataAccessPage {
    .card.e-signature {
      width: 100% !important;
    }
  }
}

@media (max-width: 416.98px) {
  .dataAccessPage {
    .form-footer {
      align-items: start !important;

      .reCAPTCHA-container>div>div>div {
        transform:scale(0.94);
        -webkit-transform:scale(0.94);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
      }
    }
  }
}

@media (max-width: 398.98px) {
  .dataAccessPage {
    .form-footer {
      .reCAPTCHA-container {
        width: 82%;
      }

      .reCAPTCHA-container>div>div>div {
        transform:scale(0.82);
        -webkit-transform:scale(0.82);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
      }
    }
  }
}

@media (max-width: 320px) {
  .dataAccessPage {
    .card.e-signature {
      .e-signature-input {
        &::placeholder {
          font-size: 90%;
        }
      }
    }

    .form-footer {
      .reCAPTCHA-container {
        width: 64%;
      }

      .reCAPTCHA-container>div>div>div {
        transform:scale(0.64);
        -webkit-transform:scale(0.64);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
      }
    }
  }
}
