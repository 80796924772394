.teamPage {
  .page-title {
    margin-bottom: 2.5rem;

    h3 {
      margin-bottom: 0;
    }
  }

  .teamMember {
    .teamMemberImage {
      height: 160px;
      width: 160px;
      background-position: center;
      background-size: cover;
      border: 8px solid $gray-400;
      border-radius: 80px;
    }

    h6 {
      color: $stanford-cardinal-red;
    }
  }
}
