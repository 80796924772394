.licensePage {
  margin-bottom: 1.25rem;

  .license-content-container {
    .material-icons {
      font-size: 1.25rem;
      line-height: 1.65;
    }
  }
}
