.mainStudyPage {
  .main-study-content-container {
    table {
      th {
        padding-left: 1rem;

        h4 {
          font-weight: 600;
        }
        
        .subhead {
          font-size: 1.2rem;
          font-weight: 400;
        }
      }

      td {
        padding-left: 1rem;
      }
    }

    .study-figure {
      padding: 1.5em;
    }

    img {
      width: 100%;
      height: auto;
    }

    .data-download-button-container {
      text-align: center;
    }
  }

  // For anchored element hiding behind
  // fixed position top navbar
  div#endurance-training {
    scroll-margin-top: 5.5em;
  }

  .show-collapse-button {
    margin-left: 0.15rem;
    margin-top: -0.25rem;
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &:focus, &:active {
      box-shadow: none;
      outline: none;
      text-decoration: none;
    }

    .material-icons {
      color: $stanford-cool-grey;
      font-size: 2.5rem;
      font-weight: normal;
      line-height: 0;
    }
  }

  .sub-section-title {
    .show-collapse-button {
      .material-icons {
        font-size: 2.15rem;
      }
    }
  }
}

.exerciseBenefitsPage {
  .exercise-benefits-page-content-container {
    .cexercise-benefits-itation-list {
      padding-left: 20px;
    }
  }
}

.studyAssaysPage {
  .study-assays-page-content-container {
    h3 {
      background: -webkit-linear-gradient(45deg, #a64d79, #1c4587);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      .study-title-species-icon {
        font-size: 1.85rem;
      }
    }

    .pass1b-06-assay-tissue-table {
      h4 {
        color: $stanford-cool-grey;

      }
      table td:first-child {
        width: 35%;
      }

      .badge-pill {
        font-size: 0.8rem;
        font-weight: 500;
        padding: 0.3rem 0.65rem;
      }
    }

    .assay-tissue-treegraph-container {
      min-height: 70vh;
    }
  }
}

// disable react-wordcloud tooltip
div[data-tippy-root=""] {
  display: none !important;
}